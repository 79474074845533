import type { ProjectMapper } from '@readme/api/src/mappings/project/mapper';

/**
 * The return type of the `projectStore` value used in `packages/react-interop/middleware/react`
 */
type ProjectStoreData = Partial<Awaited<ReturnType<(typeof ProjectMapper)['renderSingle']>>['data']>;

/**
 * A default set of prop values for use in our Reference test based
 * (used for preview.readme.io and bin.readme.com)
 */
const defaults = {
  baseUrl: '/',
  apiBaseUrl: 'http://developers.readme.local:3000',
  doc: {
    loading: true,
    title: 'Loading…',
    type: 'basic',
  },
  config: {},
  context: {
    project: {
      is_active: true,
      appearance: {
        changelog: {
          layoutExpanded: false,
          showAuthor: true,
          showExactDate: false,
        },
        colors: {
          main: '#fff',
        },
        header: {
          style: 'solid',
        },
        logo: ['https://files.readme.io/97584af-brandmark-white.svg', 'readme.svg', '60', '60', '#018EF5'],
        referenceSimpleMode: true,
        showVersion: true,
        subheaderStyle: 'links',
      },
      description: '',
      first_page: 'reference',
      flags: {
        staging: false,
      },
      metrics: {
        enabled: false,
        monthlyLimit: 0,
        thumbsEnabled: true,
      },
      modules: {
        docs: true,
        reference: true,
        changelog: true,
      },
      owlbot: {
        enabled: false,
      },
      nav_names: {
        reference: '',
      },
      oauth_url: 'https://docs.readme.com/login',
      planTrial: 'free',
      reference: {
        alwaysUseDefaults: false,
        defaultExpandResponseExample: false,
        defaultExpandResponseSchema: false,
      },
      stable: {
        version: '1.0',
      },
      subdomain: 'developers',
      topnav: {
        left: [],
        right: [],
        edited: true,
      },
      variableDefaults: [],
      versions: [
        {
          version: '1.0',
        },
      ],
    },
    projectStore: {
      data: {
        is_active: true,
        appearance: {
          table_of_contents: 'disabled',
          markdown: { callouts: { icon_font: 'emojis' } },
          brand: {
            primary_color: '#fff',
            link_color: '#000',
            theme: 'light',
          },
          changelog: {
            layout: 'continuous',
            show_author: true,
            show_exact_date: true,
          },
          custom_code: {
            css: null,
            html: {
              header: null,
              home_footer: null,
              page_footer: null,
            },
            js: null,
          },
          footer: {
            readme_logo: 'show',
          },
          header: {
            type: 'solid',
            gradient_color: null,
            overlay: {
              image: {
                uri: null,
                url: 'https://example.com/logo-header.png',
                name: 'logo-dark.png',
                width: 60,
                height: 60,
                color: '#018EF5',
                links: {
                  original_url: null,
                },
              },
              type: 'grain',
              fill: 'cover',
              position: 'center-center',
            },
          },
          navigation: {
            first_page: 'landing_page',
            left: [{ type: 'home', title: null, url: null, custom_page: null }],
            links: {
              changelog: {
                alias: null,
                label: 'Changelog',
                visibility: 'enabled',
              },
              discussions: {
                alias: null,
                label: 'Discussions',
                visibility: 'enabled',
              },
              graphql: {
                label: 'GraphQL',
                visibility: 'disabled',
              },
              guides: {
                alias: null,
                label: 'Guides',
                visibility: 'enabled',
              },
              home: {
                label: 'Home',
                visibility: 'enabled',
              },
              recipes: {
                alias: null,
                label: 'Recipes',
                visibility: 'disabled',
              },
              reference: {
                alias: null,
                label: 'API Reference',
                visibility: 'enabled',
              },
            },
            logo_link: 'homepage',
            right: [],
            sub_nav: [],
            subheader_layout: 'links',
            version: 'enabled',
          },
          logo: {
            size: 'default',
            dark_mode: {
              color: null,
              height: null,
              links: {
                original_url: null,
              },
              name: null,
              uri: null,
              url: null,
              width: null,
            },
            favicon: {
              color: null,
              height: null,
              links: {
                original_url: null,
              },
              name: null,
              uri: null,
              url: null,
              width: null,
            },
            main: {
              color: '#018EF5',
              height: 60,
              links: {
                original_url: null,
              },
              name: 'logo.png',
              uri: '/images/66aaaf94c3dc1b3cdc0e0794',
              url: 'https://files.readme.io/logo.png',
              width: 60,
            },
          },
          referenceSimpleMode: true,
          showVersion: true,
          subheaderStyle: 'links',
          whats_next_label: null,
        },
        custom_login: {
          jwt_secret: 'a-random-jwt-secret',
          login_url: 'https://docs.readme.com/login',
          logout_url: null,
        },
        description: '',
        first_page: 'reference',
        flags: {
          staging: false,
        },
        metrics: {
          enabled: false,
          monthlyLimit: 0,
          thumbsEnabled: true,
        },
        modules: {
          docs: true,
          reference: true,
          changelog: true,
        },
        owlbot: {
          enabled: false,
        },
        nav_names: {
          reference: '',
        },
        planTrial: 'free',
        reference: {
          api_sdk_snippets: 'enabled',
          defaults: 'use_only_if_required',
          json_editor: 'disabled',
          oauth_flows: 'disabled',
          request_history: 'disabled',
          response_examples: 'collapsed',
          response_schemas: 'collapsed',
        },
        stable: {
          version: '1.0',
        },
        subdomain: 'developers',
        topnav: {
          left: [],
          right: [],
          edited: true,
        },
        variableDefaults: [],
        versions: [
          {
            version: '1.0',
          },
        ],
      } as ProjectStoreData,
    },
    glossaryTerms: [{ term: 'apiKey', definition: 'This is a definition' }],
    variables: {
      defaults: [],
      user: {},
    },
    user: {
      permissions: [],
    },
    version: {
      version: '1.0',
    },
  },
  lang: 'en',
  langFull: 'English',
  loading: true,
  rdmdOpts: {},
  search: {},
  sidebars: {
    doc: [],
    refs: [],
  },
  maskErrorMessages: false,
  user: {},
  version: {
    version: '1.0',
  },
};

export default defaults;
